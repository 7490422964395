<script>
export default {
  name: 'LogoJewel',
  props: {
    fill: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
    },
  },
}
</script>

<template>
  <!-- eslint-disable max-len -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :aria-labelledby="label"
    role="presentation"
    :class="{ 'fill-current': fill }"
    viewBox="25 12 170 60"
  >
    <g id="Layer">
      <path id="Layer" fill-rule="evenodd" :fill="fill ? '#707372' : 'currentColor'" d="m115.7 20.6l-1.9 5.3q-1.1-0.2-2.1 0l2.2-6c0-0.1 0.1-0.1 0.2-0.1l1.5 0.6c0.1 0 0.1 0.1 0.1 0.2zm-4.7 5.4c-0.6 0.1-1.3 0.4-1.9 0.7v-7.3c0-0.1 0.1-0.2 0.2-0.2h1.6c0.1 0 0.2 0.1 0.2 0.2zm-2.1 0.9c-0.6 0.3-1.1 0.7-1.6 1.2v-0.1q0 0 0 0l-0.1-0.1-2.6-7.3c0-0.1 0-0.2 0.1-0.2l1.5-0.6c0.1 0 0.2 0 0.2 0.1zm-1.7 1l0.1 0.1c-0.4 0.5-0.9 1-1.2 1.5 0 0.1 0 0.1 0 0.1v-0.1q0 0 0 0 0 0 0 0 0 0 0 0h-0.1v-0.1l-5.2-6.2c-0.1 0-0.1-0.1 0-0.2l1.2-1c0-0.1 0.1-0.1 0.2 0zm-1.2 1.5v0.1c0 0 0 0 0 0-0.3 0.6-0.5 1.1-0.7 1.7v0.1l-0.1-0.1-0.1-0.1h-0.1l-7-4c-0.1 0-0.1-0.1-0.1-0.2l0.8-1.4c0-0.1 0.1-0.1 0.2-0.1l7 4zm-1.6 3.5l-8-1.4c-0.1 0-0.1-0.1-0.1-0.2l0.3-1.6c0-0.1 0.1-0.1 0.2-0.1l8 1.4 0.2 0.1h0.1v0.1c-0.2 0.6-0.3 1.1-0.4 1.7-0.1 0.7-0.1 1.3 0.1 1.9l-7.9 1.4c-0.1 0-0.2 0-0.2-0.1l-0.3-1.6c0-0.1 0-0.2 0.1-0.2l8-1.4zm0.8 4l-6.5 3.7c-0.1 0-0.2 0-0.2-0.1l-0.8-1.4c0-0.1 0-0.2 0.1-0.2l6.9-4c0.1 0.6 0.3 1.3 0.5 2zm1.1 1.8l-4.3 5.1c0 0-0.1 0.1-0.2 0l-1.2-1c0 0-0.1-0.1 0-0.2l4.7-5.6c0.3 0.7 0.6 1.3 1 1.7zm2 1.9l-2 5.4c0 0.1-0.1 0.1-0.2 0.1l-1.5-0.6c-0.1 0-0.1-0.1-0.1-0.2l2.2-6.1c0.5 0.6 1 1 1.6 1.4zm2.6 6.1h-1.6c-0.1 0-0.2-0.1-0.2-0.2v-5.4c0.3 0.1 0.6 0.3 0.9 0.4 0.3 0.1 0.7 0.2 1 0.3v4.7c0 0.1 0 0.1-0.1 0.2zm4.6-1.3l-1.5 0.6c-0.1 0-0.2 0-0.2-0.1l-1.5-4c0.7 0.1 1.3 0 2-0.1l1.3 3.5c0 0.1 0 0.2-0.1 0.1zm4-2.4l-1.2 1c0 0.1-0.1 0.1-0.2 0l-2.2-2.6c0.6-0.3 1.2-0.6 1.7-1l1.9 2.3q0.2 0 0 0.3zm2.8-3.9l-0.8 1.4c0 0.1-0.1 0.1-0.2 0.1l-2.4-1.4c0.4-0.5 0.8-1 1.1-1.6l2.3 1.3c0.1 0 0.1 0.1 0 0.2zm1-2.9l-2.6-0.5c0.1-0.6 0.2-1.3 0.2-1.9l2.7 0.5c0.1 0 0.1 0.1 0.1 0.2l-0.3 1.6c0 0.1-0.1 0.1-0.1 0.1zm0.3-4.7l-2.9 0.5c-0.2-0.6-0.4-1.2-0.7-1.8l3.2-0.6c0.1 0 0.2 0 0.2 0.1l0.3 1.6c0 0.1 0 0.2-0.1 0.2zm-1.3-4.6l-3.1 1.8c-0.4-0.5-0.9-1-1.4-1.4l3.6-2.1c0.1 0 0.2 0 0.2 0.1l0.8 1.4c0 0.1 0 0.2-0.1 0.2zm-2.8-3.8l-2.9 3.5c-0.3-0.1-0.7-0.3-1-0.4q-0.4-0.1-0.9-0.3l3.4-4c0 0 0.1-0.1 0.2 0l1.2 1c0.1 0.1 0.1 0.2 0 0.2z" />
      <g id="Layer">
        <path id="Layer" :fill="fill ? '#707372' : 'currentColor'" d="m29 70.6v-2h1.2v0.1c0 0.2 0 0.3 0 0.4q0 0.9 0.9 0.9c0.5 0 0.9-0.2 1-0.5 0.2-0.4 0.3-1 0.3-2v-8.1c0-0.7-0.1-1.1-0.2-1.2-0.1-0.1-0.4-0.2-1-0.3h-0.2v-0.7h5v0.7h-0.1c-0.5 0-0.9 0.1-1 0.3-0.1 0.1-0.2 0.5-0.2 1.2v7.4c0 1.4-0.3 2.5-0.9 3.1q-0.9 0.9-3 0.9c-0.4 0-1.1-0.1-1.8-0.2z" />
        <path id="Layer" :fill="fill ? '#707372' : 'currentColor'" d="m49.3 68.5h-9v-0.7h0.2c0.5 0 0.9-0.1 1-0.3 0.1-0.2 0.2-0.5 0.2-1.2v-6.9c0-0.7-0.1-1.1-0.2-1.2-0.1-0.1-0.4-0.2-1-0.3h-0.2v-0.7h8.7v2h-1.2v-0.1c0-0.3 0-0.4 0-0.5 0-0.3-0.1-0.5-0.2-0.6-0.1-0.1-0.5-0.1-1-0.1h-2.6v4.3h1.6c0.6 0 1 0 1.1 0 0.1 0 0.1-0.3 0.2-0.7v-0.1h0.8v2.5h-0.8v-0.1c0-0.4-0.1-0.6-0.2-0.7-0.1-0.1-0.5-0.1-1-0.1h-1.7v3.4c0 0.8 0.1 1.3 0.3 1.4 0.2 0 0.6 0.1 1.3 0.1h1c0.8 0 1.2 0 1.3-0.1 0.1-0.1 0.2-0.4 0.2-1.1v-0.1h1.2z" />
        <path id="Layer" :fill="fill ? '#707372' : 'currentColor'" d="m56.4 68.7l-2.7-9.2c-0.2-0.8-0.4-1.2-0.5-1.3-0.1-0.1-0.4-0.2-0.8-0.2h-0.2v-0.7h4.6v0.7h-0.2c-0.6 0-1 0.1-1 0.3q0 0.3 0.3 1.2l1.6 5.2 2.4-7.3h0.9l2.6 7.8 1.9-5.7c0-0.1 0.1-0.3 0.2-0.7 0.1-0.4 0.1-0.5 0.1-0.6q0-0.3-0.9-0.3h-0.1v-0.7h3.1v0.7h-0.1c-0.2 0-0.3 0-0.4 0-0.3 0-0.4 0-0.5 0.1-0.1 0.1-0.2 0.6-0.5 1.4l-3.1 9.2h-0.8l-2.6-7.9-2.6 7.9h-0.7z" />
        <path id="Layer" :fill="fill ? '#707372' : 'currentColor'" d="m79.6 68.5h-9v-0.7h0.2c0.5 0 0.9-0.1 1-0.3 0.1-0.2 0.2-0.5 0.2-1.2v-6.9c0-0.7-0.1-1.1-0.2-1.2-0.1-0.1-0.4-0.2-1-0.3h-0.2v-0.7h8.7v2h-1.2v-0.1c0-0.3 0-0.4 0-0.5 0-0.3-0.1-0.5-0.2-0.6-0.1-0.1-0.5-0.1-1-0.1h-2.5v4.3h1.6c0.6 0 1 0 1.1 0 0.1 0 0.1-0.3 0.2-0.7v-0.1h0.8v2.5h-0.8v-0.1c0-0.4-0.1-0.6-0.2-0.7-0.1-0.1-0.5-0.1-1-0.1h-1.7v3.4c0 0.8 0.1 1.3 0.3 1.4 0.2 0 0.6 0.1 1.3 0.1h1c0.8 0 1.2 0 1.3-0.1 0.1-0.1 0.2-0.4 0.2-1.1v-0.1h1.2v1.9z" />
        <path id="Layer" :fill="fill ? '#707372' : 'currentColor'" d="m92 68.5h-9.1v-0.7h0.2c0.5 0 0.9-0.1 1-0.3 0.1-0.2 0.2-0.5 0.2-1.2v-6.9c0-0.7-0.1-1.1-0.2-1.2-0.1-0.1-0.4-0.2-1-0.3h-0.2v-0.7h5v0.7h-0.1c-0.5 0-0.9 0.1-1 0.3-0.1 0.1-0.2 0.5-0.2 1.2v6.9c0 0.8 0.1 1.3 0.3 1.4 0.2 0 0.6 0.1 1.3 0.1h1c0.4 0 0.8 0 1.1-0.1 0.2 0 0.3-0.1 0.4-0.2 0-0.1 0.1-0.6 0.1-1.5v-0.1h1.2z" />
        <path id="Layer" fill-rule="evenodd" :fill="fill ? '#707372' : 'currentColor'" d="m106.1 66.4c0 0.7 0 1.1 0.1 1.2 0.1 0.1 0.4 0.2 0.8 0.3h0.1v0.7h-4.7v-0.7h0.2c0.5 0 0.9-0.1 1-0.3 0.1-0.2 0.2-0.5 0.2-1.2v-6.9c0-0.7-0.1-1.1-0.2-1.2-0.1-0.1-0.4-0.2-1-0.3h-0.2v-0.7h5.6c2.4 0 3.7 1 3.7 2.9 0 0.8-0.2 1.4-0.6 2-0.4 0.5-0.9 1-1.7 1.3l2.3 3.1c0.6 0.7 0.9 1.2 1.1 1.3 0.2 0.1 0.5 0.2 0.9 0.2h0.2v0.7h-3.5l-3.3-5h-1zm0.4-3.6c0.8 0 1.5-0.2 2-0.7 0.5-0.5 0.7-1.1 0.7-1.9 0-1.5-0.8-2.2-2.3-2.2h-0.9v4.8z" />
        <path id="Layer" :fill="fill ? '#707372' : 'currentColor'" d="m125.6 68.5h-9v-0.7h0.2c0.5 0 0.9-0.1 1-0.3 0.1-0.2 0.2-0.5 0.2-1.2v-6.9c0-0.7-0.1-1.1-0.2-1.2-0.1-0.1-0.4-0.2-1-0.3h-0.2v-0.7h8.7v2h-1.2v-0.1c0-0.3 0-0.4 0-0.5 0-0.3-0.1-0.5-0.2-0.6-0.1-0.1-0.5-0.1-1-0.1h-2.5v4.3h1.6c0.6 0 1 0 1.1 0 0.1 0 0.1-0.3 0.2-0.7v-0.1h0.8v2.5h-0.8v-0.1c0-0.4-0.1-0.6-0.2-0.7-0.1-0.1-0.5-0.1-1-0.1h-1.7v3.4c0 0.8 0.1 1.3 0.3 1.4 0.2 0 0.6 0.1 1.3 0.1h1c0.8 0 1.2 0 1.3-0.1 0.1-0.1 0.2-0.4 0.2-1.1v-0.1h1.2v1.9z" />
        <path id="Layer" :fill="fill ? '#707372' : 'currentColor'" d="m129.4 68.4v-2.4h1.2v0.2c0 0.7 0.2 1.1 0.4 1.3 0.5 0.4 1.1 0.6 1.7 0.6q0.9 0 1.5-0.6 0.6-0.6 0.6-1.5c0-0.5-0.1-0.9-0.3-1.1-0.2-0.3-0.7-0.6-1.3-0.9l-0.8-0.4c-2-1-3-2.2-3-3.4 0-1 0.4-1.7 1.1-2.3 0.7-0.6 1.7-0.9 2.8-0.9 0.9 0 2 0.2 3.2 0.5v2.3h-1.2v-0.1c0-0.7-0.2-1.2-0.3-1.3q-0.6-0.6-1.5-0.6c-0.5 0-0.9 0.2-1.3 0.5-0.4 0.4-0.5 0.8-0.5 1.3 0 0.4 0.1 0.7 0.3 1 0.2 0.3 0.7 0.6 1.3 0.9l0.8 0.4c1.2 0.6 2 1.2 2.4 1.7q0.6 0.7 0.6 1.8c0 1-0.4 1.9-1.2 2.5q-1.2 0.9-3.3 0.9c-0.9 0-2-0.1-3.2-0.4z" />
        <path id="Layer" fill-rule="evenodd" :fill="fill ? '#707372' : 'currentColor'" d="m145.8 68.8c-1.9 0-3.3-0.5-4.4-1.6-1.1-1.1-1.6-2.5-1.6-4.4 0-1.8 0.5-3.3 1.6-4.4 1.1-1.1 2.5-1.6 4.4-1.6 1.9 0 3.3 0.5 4.4 1.6 1.1 1.1 1.6 2.5 1.6 4.4 0 1.8-0.5 3.3-1.6 4.4-1.1 1.1-2.5 1.6-4.4 1.6zm0-0.7c1.1 0 2-0.4 2.5-1.3 0.6-0.9 0.8-2.2 0.8-3.9 0-1.7-0.3-3-0.8-3.9-0.6-0.9-1.4-1.3-2.5-1.3-1.1 0-2 0.4-2.5 1.3-0.6 0.9-0.8 2.2-0.8 3.9 0 1.7 0.3 3 0.8 3.9 0.5 0.9 1.4 1.3 2.5 1.3z" />
        <path id="Layer" fill-rule="evenodd" :fill="fill ? '#707372' : 'currentColor'" d="m158.3 66.4c0 0.7 0 1.1 0.1 1.2 0.1 0.1 0.4 0.2 0.8 0.3h0.1v0.7h-4.7v-0.7h0.2c0.5 0 0.9-0.1 1-0.3 0.1-0.2 0.2-0.5 0.2-1.2v-6.9c0-0.7-0.1-1.1-0.2-1.2-0.1-0.1-0.4-0.2-1-0.3h-0.2v-0.7h5.6c2.4 0 3.7 1 3.7 2.9 0 0.8-0.2 1.4-0.6 2-0.4 0.5-0.9 1-1.7 1.3l2.3 3.1c0.6 0.7 0.9 1.2 1.1 1.3 0.2 0.1 0.5 0.2 0.9 0.2h0.2v0.7h-3.5l-3.3-5h-1zm0.4-3.6c0.8 0 1.5-0.2 2-0.7 0.5-0.5 0.7-1.1 0.7-1.9 0-1.5-0.8-2.2-2.3-2.2h-0.9v4.8z" />
        <path id="Layer" :fill="fill ? '#707372' : 'currentColor'" d="m172.2 57.9h-1.9c-0.5 0-0.8 0.1-1 0.2-0.1 0.1-0.2 0.5-0.2 1.2v0.2h-1.1v-2.4h11v2.4h-1.2v-0.2c0-0.7-0.1-1.1-0.2-1.2-0.1-0.1-0.5-0.2-1-0.2h-1.9v8.4c0 0.8 0.1 1.2 0.2 1.3 0.1 0.1 0.5 0.2 1.1 0.2h0.1v0.7h-5.4v-0.7h0.1q0.9 0 1.2-0.3c0.1-0.1 0.2-0.5 0.2-1.2z" />
        <path id="Layer" :fill="fill ? '#707372' : 'currentColor'" d="m182.6 68.4v-2.4h1.2v0.2c0 0.7 0.2 1.1 0.4 1.3 0.5 0.4 1.1 0.6 1.7 0.6q0.9 0 1.5-0.6 0.6-0.6 0.6-1.5c0-0.5-0.1-0.9-0.3-1.1-0.2-0.3-0.7-0.6-1.3-0.9l-0.8-0.4c-2-1-3-2.2-3-3.4 0-1 0.4-1.7 1.1-2.3 0.7-0.6 1.7-0.9 2.8-0.9 0.9 0 2 0.2 3.2 0.5v2.3h-1.2v-0.1c0-0.7-0.2-1.2-0.3-1.3q-0.6-0.6-1.5-0.6c-0.5 0-0.9 0.2-1.3 0.5-0.4 0.4-0.5 0.8-0.5 1.3 0 0.4 0.1 0.7 0.3 1 0.2 0.3 0.7 0.6 1.3 0.9l0.8 0.4c1.2 0.6 2 1.2 2.4 1.7q0.6 0.7 0.6 1.8c0 1-0.4 1.9-1.2 2.5q-1.2 0.9-3.3 0.9c-0.9 0-2-0.1-3.2-0.4z" />
      </g>
    </g>
  </svg>
</template>
